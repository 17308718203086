var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fieldsGroups.length)?_c('div',{staticClass:"quick-view"},[_c('div',{staticClass:"banner-container",style:({ 'width': parseInt( _vm.bannerStyles['width'].replace('px', '') ) + 2 + 'px' })},[_c('div',{ref:"banner",staticClass:"releasecat_banner",style:({ ..._vm.bannerStyles, 'padding-left': 0, 'padding-right': 0, 'overflow': 'hidden' })},[_vm._l((_vm.fieldsGroups),function(fieldsGroup){return _c('FieldsGroupValue',{key:fieldsGroup[0].id,attrs:{"fieldsGroup":fieldsGroup,"bannerStyles":_vm.bannerStyles}})}),(_vm.activeStream.attributes.feedback)?_c('div',{staticClass:"feedback",style:({ 
					'padding-left': _vm.bannerStyles['padding-left'],
					'padding-right': _vm.bannerStyles['padding-right'],
					'margin-top': 10 + 'px'
				})},_vm._l((['Good', 'Bad']),function(icon,index){return _c('img',{key:index,staticClass:"feedback__icon",style:({
						'filter': _vm.activeFeedback && _vm.activeFeedback === icon ? 'grayscale(0%)' : 'grayscale(100%)'
					}),attrs:{"src":_vm.getFeedbackIconUrl(icon)},on:{"click":function($event){_vm.activeFeedback = icon}}})}),0):_vm._e()],2)]),_c('div',{staticClass:"manage-panel",style:({ 'width': _vm.bannerStyles['width'] })},[_c('button',{staticClass:"preview",on:{"click":_vm.previewPopup}},[_vm._v("Preview Popup")]),_c('button',{staticClass:"preview",on:{"click":_vm.previewSidePanel}},[_vm._v("Preview Side Panel")]),(!_vm.isPublished)?_c('button',{staticClass:"publish",on:{"click":_vm.publishBanner}},[_vm._v("Publish")]):_c('button',{staticClass:"unpublish",on:{"click":_vm.unpublishBanner}},[_vm._v("Unpublish")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }