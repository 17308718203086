var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fields-editor",class:{'onboarding': _vm.isFieldTypesOnboarding},on:{"mouseup":_vm.stopDragging,"mouseleave":_vm.stopDragging}},[_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideHeadersMenu),expression:"hideHeadersMenu"}],staticClass:"types"},[(_vm.isAddingLoader || _vm.isDeletingLoader)?_c('div',{staticClass:"cover"}):_vm._e(),_vm._l((_vm.types),function(type,index){return _c('li',{key:index,class:type.toLowerCase(),on:{"click":function($event){$event.stopPropagation();return _vm.addField(type)}}},[_c('i',{staticClass:"icon"}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(type))])])}),_c('transition',{attrs:{"mode":"out-in","name":"menu"}},[(_vm.isShowHeadersMenu)?_c('div',{staticClass:"headers-menu"},[_c('button',{style:(_vm.getHeaderStyle('Header 1')),attrs:{"type":"button"},on:{"click":function($event){return _vm.addField('Header 1')}}},[_vm._v("Header 1")]),_c('button',{style:(_vm.getHeaderStyle('Header 2')),attrs:{"type":"buton"},on:{"click":function($event){return _vm.addField('Header 2')}}},[_vm._v("Header 2")])]):_vm._e()])],2),_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.resetActiveField),expression:"resetActiveField"}],ref:"fieldsContainer",staticClass:"fields",on:{"mousemove":_vm.moveDragging}},_vm._l((_vm.fields),function(field){return _c('li',{key:field.id,ref:'field-' + field.id,refInFor:true,class:{
				active: _vm.activeField && _vm.activeField.id === field.id,
				hidden: !_vm.isFieldVisible(field),
			},on:{"click":function($event){return _vm.setActiveField(field)}}},[_c('div',{staticClass:"body"},[(
						!_vm.isAddingLoader &&
						!_vm.isDeletingLoader &&
						!field.attributes.relatedGroupId
					)?_c('button',{staticClass:"move",on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDragging($event, field)}}}):(_vm.isAddingLoader || _vm.isDeletingLoader)?_c('span',{staticClass:"move-placeholder"}):_c('span',{staticClass:"move-placeholder"}),_c('img',{style:({
						'margin-right': field.attributes.type === 'html' ? '0' : '15px',
						'height': field.attributes.type.startsWith('header') ? '26px' : '28px',
					}),attrs:{"src":_vm.getTypeIconSrc('grey', field.attributes.type)}}),_c('FieldInput',{attrs:{"fieldId":field.id},on:{"click":function($event){$event.stopPropagation();}}}),(!_vm.isDeletingLoader && !_vm.isAddingLoader)?_c('button',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteField(field)}}}):_c('span',{staticClass:"delete-placeholder"})],1),_c('div',{staticClass:"toolbar-container"},[_c('transition',{attrs:{"mode":"out-in","name":"toolbar"}},[(_vm.activeField && _vm.activeField.id === field.id)?_c('Toolbar',{attrs:{"fieldId":field.id}}):_vm._e()],1)],1)])}),0),(_vm.fields.length)?_c('div',{staticClass:"banner-menu"},[_c('button',{staticClass:"preview",on:{"click":_vm.previewSidePanel}},[_vm._v("Preview Side Panel")]),_c('button',{staticClass:"preview",on:{"click":_vm.previewPopup}},[_vm._v("Preview Popup")]),(!_vm.isPublished)?_c('button',{staticClass:"publish",on:{"click":_vm.publishBanner}},[_vm._v("Publish")]):_c('button',{staticClass:"unpublish",on:{"click":_vm.unpublishBanner}},[_vm._v("Unpublish")])]):_vm._e(),_c('div',{staticClass:"background-input-container"},[_c('p',[_vm._v(" Post background color: ")]),_c('button',{staticClass:"color-picker",attrs:{"type":"button"},on:{"click":_vm.openColorPicker}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postBackgroundColor),expression:"postBackgroundColor"}],ref:"bgColorInput",staticStyle:{"display":"none"},attrs:{"type":"color"},domProps:{"value":(_vm.postBackgroundColor)},on:{"input":function($event){if($event.target.composing)return;_vm.postBackgroundColor=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postBackgroundColor),expression:"postBackgroundColor"}],staticClass:"input-color",attrs:{"type":"text"},domProps:{"value":(_vm.postBackgroundColor)},on:{"input":function($event){if($event.target.composing)return;_vm.postBackgroundColor=$event.target.value}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }