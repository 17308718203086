var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
			..._vm.fieldStyles, 
			'width': 'inherit',
			'max-width': '100%'
		})},[(_vm.field.attributes.type === 'date')?_c('div',{staticStyle:{"font-family":"inherit"}},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.field.attributes.value))+" ")]):(_vm.field.attributes.type === 'header_1' 
			|| _vm.field.attributes.type === 'header_2'
			|| _vm.field.attributes.type === 'header_1_group'
			|| _vm.field.attributes.type === 'header_2_group')?_c('div',{staticStyle:{"font-family":"inherit"}},[_vm._v(" "+_vm._s(_vm.field.attributes.value)+" ")]):(_vm.field.attributes.type === 'text' || _vm.field.attributes.type === 'text_group')?_c('div',{ref:"textContainer",staticStyle:{"font-family":"inherit"}}):(_vm.field.attributes.type === 'html')?_c('div',{ref:"htmlContainer",staticStyle:{"font-family":"inherit","white-space":"normal"},domProps:{"innerHTML":_vm._s(_vm.field.attributes.value)}}):(_vm.field.attributes.type === 'image' && _vm.field.attributes.image)?_c('img',{style:({'width': _vm.fieldStyles['width'], 'max-width': '100%', 'object-fit': 'inherit' }),attrs:{"src":_vm.createImageURL(_vm.field.attributes.image)}}):(_vm.field.attributes.type === 'youtube' && _vm.field.attributes.value.length)?_c('iframe',{staticStyle:{"height":"inherit","width":"100%"},attrs:{"src":_vm.field.attributes.value,"frameborder":"0","allowfullscreen":""}}):(_vm.field.attributes.type === 'list' && _vm.field.attributes.value.length)?_c('ul',{staticStyle:{"font-size":"inherit","padding-left":"20px","font-family":"inherit"}},_vm._l((_vm.getBulletpointsLines()),function(line,index){return _c('li',{key:index,staticStyle:{"font-size":"inherit","font-family":"inherit"}},[_vm._v(_vm._s(line))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }